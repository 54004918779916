#MathJax_Zoom {
	        background-color: rgba(10,10,10,0.97) !important;
		box-shadow: none !important;
		-webkit-box-shadow: 5px 5px 15px $background-color !important;
		-moz-box-shadow: 5px 5px 15px $background-color !important;
		-khtml-box-shadow: 5px 5px 15px $background-color !important;
	}

.icon-bar {
 position: absolute;
  left: 100%;
}

.icon-bar a {
  display: block;
  text-align: center;
  padding: 10px;
  transition: all 0.3s ease;
  color: white;
  font-size: 15px;
}

.icon-bar a:visited {
	color: white;
	background-color: #303030;
}

.icon-bar a:hover {
  background-color: #000;
}

.twitter {
  background: #55ACEE;
  color: white;
}

.linkedin {
  background: #007bb5;
  color: white;
}
