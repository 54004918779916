/**
 * Site header
 */
.site-header {
  border-bottom: 1px solid #555;
  padding: 10px 0;
  z-index: 10;
  // Positioning context for the mobile navigation icon
  position: relative;

	@media screen and (max-width: $on-palm)
	{
  		border-bottom: 1px solid #555;//$grey-color-light;
  		padding: 10px 0;
  		z-index: 10;
		display: table;

		a
		{
			width: 100vw;
		}		
		> .wrapper
		{
			max-width: 70vw;
			margin-left: auto;
			margin-right: auto;
			display: table-row-group;
			> .search-bar
			{
				margin: 10px 0;
				float: none;
				display: flex;
				width: 100vw;
				> input
				{
					width: 50%;
					margin-left: auto;
					margin-right: auto;
					&:focus
					{
						width: 60%;
					}
				}
			}
		}
	}
}

.site-title {
  @include relative-font-size(1.625);
  font-weight: 300;
  line-height: $base-line-height * $base-font-size * 2;
  float: left;
}


.content-table
{

	background: lighten($background-color, 11%);
	width: fit-content;
	padding: 10px 25px 5px 25px;
	margin-bottom: 20px;

	> p {
		margin-bottom: 5px;
	}
}


.search-bar
{
	float: right;
	margin: ($spacing-unit / 3) * 2;
}

.site-nav {
  float: right;
  line-height: $base-line-height * $base-font-size * 1.875;
  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;

    // Gaps between nav items, but not on the last one
    &:not(:last-child) {
      margin-right: 20px;
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  border-top: 1px solid #555;
  z-index: 10;
  padding: $spacing-unit 0;
}

.footer-heading {
  @include relative-font-size(1.5);
  margin-bottom: $spacing-unit / 4;
}

.footer-margin {
	padding-bottom: $spacing-unit / 3;
}

.contact-list
{
	list-style: none;
}

.social-media-list {
  list-style: none;
  display: inline-flex;
}

.footer-col-wrapper {
	width: 100%;
	display: table;
  @include relative-font-size(0.9375);
  color: $grey-color;
  text-align: center;
  @extend %clearfix;
}

.footer-table
{
	display: table-row-group;
}

.footer-col {
  display: inline;
  width: auto;
  margin: 10px;
}


/**
 * Page content
 */
.page-content {
  padding: $spacing-unit 0;
  flex: 1;
	@media screen and (max-width: $on-palm)
	{
		.wrapper
		{
			max-width: 80vw;
		}
	}
}

.page-heading {
  @include relative-font-size(2);
}

.post-list-heading {
  @include relative-font-size(1.75);
}

.image-caption {
	text-align: center;

	> img {
		display: block;
		margin: auto;
		max-width: 100%;
		padding-bottom: 15px;

	}
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {

    &:hover {
    box-shadow: 0 2px 20px 12px rgba(0, 0, 0, 0.1), 0 2px 15px 12px rgba(3, 3, 3, 0.12);
    }
    background-color: #0f0f0f;
    padding: 10px 20px 10px 20px;
    margin-bottom: $spacing-unit;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
}

.post-link {
  display: block;
  @include relative-font-size(1.5);
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit/2;
}

.post-title {
  @include relative-font-size(2.625);
  letter-spacing: -1px;
  line-height: 1;

}

.post-content {
  position: relative;
  margin-bottom: $spacing-unit/2;
  padding: 40px 40px 40px 40px;
  background-color: rgba(17,17,17, 70);

  h1 {
	  @include relative-font-size(2.7);
  }
  h2 {
    @include relative-font-size(2.1);
  }

  h3 {
    @include relative-font-size(1.725);

    }

  h4 {
    @include relative-font-size(1.25);

  }
  a {
	  word-break: break-all;
  }
}

/**
 * Pagination
 */

.pagination a, .pagination span {
  padding: 7px 18px;
  border: 1px solid #222;
  margin-left: -2px;
  margin-right: -2px;
  background-color: #1a1a1a;
  display: inline-block;
}

.pagination a {
  &:hover {
      background-color: #f1f1f1;
      color: #333;
  }
}

.pagination {
  text-align: center;
}
